import hubRepository from './hubRepository'
import peerRepository from './peerRepository'

const repositories = {
  hub: hubRepository,
  peer: peerRepository,
}

// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
  get: name => repositories[name],
}
