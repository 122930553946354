import axiosIns from "@/libs/axios";

const resource = "/v1/data";

export default {
  async getPeerList() {
    axiosIns.defaults.baseURL = process.env.VUE_APP_PEER_URL || "http://localhost:4000";
    const res = await axiosIns.get(resource);
    return res.data;
  },
};
