<template>
  <div>
    <b-col cols="12">
      <b-tabs
        card
        pills
        fill
        nav-wrapper-class="pt-1 pb-0"
        content-class="mt-1"
      >
        <b-tab title="Hubs">
          <b-alert variant="info" show class="mb-0">
            <div class="alert-body">
              <b-table
                striped
                hover
                :items="hubList"
                :fields="hubs.fields"
              ></b-table>
            </div>
          </b-alert>
        </b-tab>

        <!-- This tabs content will not be mounted until the tab is shown -->
        <!-- and will be un-mounted when hidden -->
        <b-tab
          v-for="(props, index) in peersProps"
          :key="index"
          :title="props.fieldName"
          lazy
        >
          <b-alert variant="info" show class="mb-0">
            <div class="alert-body">
              <b-table
                striped
                hover
                :items="props.data"
                :fields="props.fields"
              ></b-table>
            </div>
          </b-alert>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BBadge,
  BTabs,
  BTab,
  BTable,
  BCard,
  BImg,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
} from "bootstrap-vue";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import { isEmpty, first } from "lodash";

const hubRepository = RepositoryFactory.get("hub");
const peerRepository = RepositoryFactory.get("peer");

export default {
  components: {
    BCol,
    BRow,
    BBadge,
    BTabs,
    BTab,
    BTable,
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    VBTooltip,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hubs: {
        fields: [
          {
            key: "id",
            label: "Id",
            sortable: true,
          },
          {
            key: "address",
            label: "Address",
            sortable: true,
          },
          {
            key: "last_update",
            label: "Last Update",
            sortable: false,
          },
        ],
        data: [],
      },
      peers: {
        fields: [],
        data: [],
      },
      peersProps: {
        decentralizedData: {
          fieldName: "Decentralized Data",
          fields: [
            {
              key: "pubkey",
              label: "Public Key",
              sortable: true,
            },
            {
              key: "content",
              label: "Content",
              sortable: false,
            },
          ],
        },
        dataIntegrity: {
          fieldName: "Data Integrity",
          fields: [
            {
              key: "id",
              label: "Id",
              sortable: true,
            },
            {
              key: "content",
              label: "Content",
              sortable: false,
            },
            {
              key: "hash",
              label: "Hash",
              sortable: false,
            },
            {
              key: "is_verified",
              label: "Is Verified",
              sortable: false,
            },
          ],
          data: [],
        },
        decentralizedCaching: {
          fieldName: "Decentralized Caching",
          fields: [
            {
              key: "id",
              label: "Id",
              sortable: true,
            },
            {
              key: "content",
              label: "Content",
              sortable: false,
            },
            {
              key: "media_type",
              label: "Media Type",
              sortable: false,
            },
            {
              key: "media_address",
              label: "Media Address",
              sortable: false,
            },
          ],
          data: [],
        },
      },
    };
  },
  computed: {
    hubList() {
      return Object.entries(this.hubs.data.data ?? {}).reduce(
        (acc, [key, value]) => {
          const hub = {
            id: key,
            address: value.Address,
            last_update: value.LastUpdate,
          };
          acc.push(hub);
          return acc;
        },
        []
      );
    },
  },
  async mounted() {
    document.title = "Home";
    this.poll();
    setInterval(async () => {
      this.poll();
    }, 5000);
  },
  methods: {
    async poll() {
      this.hubs.data = await hubRepository.getHubList();
      this.peers.data = await peerRepository.getPeerList();
      Object.values(this.peersProps).forEach((props) => {
        switch (props.fieldName) {
          case "Decentralized Data":
            return (this.peersProps.decentralizedData.data = Object.values(
              this.peers.data.data ?? {}
            ).reduce((acc, value) => {
              const peer = {
                pubkey: value.pubkey,
                content: value.content,
              };
              acc.push(peer);
              return acc;
            }, []));
          case "Data Integrity":
            return (this.peersProps.dataIntegrity.data = Object.values(
              this.peers.data.data ?? {}
            ).reduce((acc, value) => {
              if (isEmpty(value.tags)) return acc;
              value.tags = first(value.tags);
              if (value.tags[0] !== "hash") return acc;
              const peer = {
                id: value.id,
                content: value.content,
                hash: value.tags[1],
                is_verified: value.tags[2] === "true",
              };
              acc.push(peer);
              return acc;
            }, []));
          case "Decentralized Caching":
            return (this.peersProps.decentralizedCaching.data = Object.values(
              this.peers.data.data ?? {}
            ).reduce((acc, value) => {
              if (isEmpty(value.tags)) return acc;
              if (value.tags[0] !== "audio") return acc;
              const peer = {
                id: value.id,
                content: value.content,
                media_type: value.tags[0],
                media_address: value.tags[1],
              };
              acc.push(peer);
              return acc;
            }, []));
          default:
            return [];
        }
      });
    },
  },
};
</script>
<style scss>
th {
  word-break: initial;
}
td {
  word-break: break-word;
}
</style>
