import axiosIns from "@/libs/axios";

const resource = "/v1/data";

export default {
  async getHubList() {
    axiosIns.defaults.baseURL = process.env.VUE_APP_HUB_URL || "http://localhost:3030";
    const res = await axiosIns.get(resource);
    return res.data;
  },
};
